import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  BannerLinks,
  TextBox,
  BlogSlider,
  Appointment,
  Breadcrumbs
} from '../../components'

import cards from '../../components/BlogSlider/DefaultCards'
import boxes from '../../components/BannerLinks/DefaultBox'

import BannerImage from '../../assets/header.customerportal.jpg'
import Georg from '../../assets/georg_neu.png'

import { generateBreadcrumbsLinks } from '../../utils/index'

const CustomerPortal = () => {
  const links = generateBreadcrumbsLinks('/blog/customer-portal', 'Kunden-Ticketsystem')
  return (
    <Layout>
      <Helmet
        title="Ticketsystem für unsere Kund:innen"
        meta={[
          {
            name: 'description',
            content:
              'Die besten Informationen nützen nichts, wenn Sie nicht für alle Beteiligten aufrufbar sind. Deshalb setzt holzweg seit vielen Jahren in der Kommunikation mit unseren Kund:innen unser eigenes Customer Portal ein.'
          },
          {
            name: 'keywords',
            content:
              'Ticketsystem holzweg, Blog holzweg, Innsbruck, holzweg, Blog, Ticketsystem, Kunde holzweg, Customerportal, customer portal, tasks, bugs'
          },
          {
            name: 'image',
            content:
              'https://www.holzweg.com/static/header.customerportal-28ad9d576b1ca96d8267d790615bd8fa.jpg'
          },
          {
            property: 'og:description',
            content:
              'Die besten Informationen nützen nichts, wenn Sie nicht für alle Beteiligten aufrufbar sind. Deshalb setzt holzweg seit vielen Jahren in der Kommunikation mit unseren Kund:innen unser eigenes Customer Portal ein.'
          },
          {
            property: 'og:image',
            content:
              'https://www.holzweg.com/static/header.customerportal-28ad9d576b1ca96d8267d790615bd8fa.jpg'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            Wir und Sie:
            <br />
            1 Team
          </span>
        }
      />
      <Breadcrumbs
          backLinkAlias=' '
          links={links}
        />
      <TextBox
        title="Ticketsystem für unsere Kund:innen"
        text={
          <span>
            <p>Die besten Informationen nützen nichts, wenn Sie nicht für alle Beteiligten aufrufbar sind. Deshalb setzt holzweg seit vielen Jahren in der Kommunikation mit unseren Kund:innen unser eigenes Customer Portal ein.
            </p>
            <p>Neben der gesammelten Darstellung aller Wünsche, Features und Tasks schafft die Verwendung unseres Ticketsystems vor allem eines: <b>Transparenz.</b>
            </p>
            <p><b>Aktuelle Tasks sind über das Ticketsystem jederzeit von allen Projektbeteiligten einsehbar!</b>
            </p>
            <p>Auch vergangene Tasks können über das Archiv aufgerufen werden. Ein Informationsverlust kann dadurch weitgehend ausgeschlossen werden. Die transparente Darstellung der Schritte zwischen Ihnen und holzweg wird zusätzlich durch die Zuweisung der Tickets an die derzeit zuständige Person gestärkt. Es ist also für alle Beteiligten zu jedem Zeitpunkt klar, wer derzeit für die weitere Bearbeitung der Aufgabe verantwortlich ist.
            </p>
            <p><b>Im Gegensatz zu E-Mails</b> ist es auch für andere Personen mit Zugang zu einem Projekt möglich, bereits Geschriebenes einzusehen, zu beantworten und an andere Personen zuzuweisen etc.
            </p>
            <h3>Was kann das Customer Tool also:</h3>
            <ul>
              <li>Es handelt sich beim Customer Portal um ein Webtool, welches man weltweit einfach über eine URL aufgerufen kann.</li>
              <li>Informationen werden dauerhaft gespeichert und können nicht verloren gehen.</li>
              <li>Todos sind übersichtlich aufgelistet.</li>
              <li>Zuständigkeiten sind klar ersichtlich.</li>
            </ul>
            <p>Das Ticketsystem macht die Aufgabenabwicklung schneller, flexibler und übersichtlicher als es mit herkömmlichen Kommunikationsmedien möglich wäre.
            </p>
          </span>
        }
      />
      <Appointment image={Georg} />
      <TextBox titleMods={['center']}
        title="Weitere Blogeinträge"
        text=""
      />
      <BlogSlider cards={cards} singleRow={true} />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}
export default CustomerPortal
